<script setup>

    import { compute } from '@/Helpers/Helpers';
    import * as rrule from '@/Helpers/rrule';
    import { computedAsync, useShare } from '@vueuse/core';
    import * as calendarLink from 'calendar-link';
    import { memoize, sortBy } from 'lodash';
    import { inject, ref, shallowRef, toRaw } from 'vue';

    const props = defineProps({
        eventId: String,
        items: {
            type: Array,
            default: []
        },
        timezone: String,
        allVenues: {
            type: Array,
            default: []
        },
        allTypes: {
            type: Array,
            default: []
        },
        allHosts: {
            type: Array,
            default: []
        },
        speakers: {
            type: Array,
            default: []
        },
        allTags: {
            type: Array,
            default: []
        },
        userFavorites: {
            type: Array,
            default: [],
            required: false,
        },
        disableActions: Boolean,
        userTickets: {
            type: Object,
            default: {}
        },
        itemWithFocus: {
            type: String,
            default: null,
        },
    });

    const emits = defineEmits(['toggleLike']);

    const locale = inject('locale');
    const route = inject('route');

    // 

    const typesFilter = ref([]);

    const venuesFilter = ref([]);

    const hostsFilter = ref([]);

    const speakersFilter = ref([]);

    const tagsFilter = ref([]);

    const favoritesFilter = shallowRef(false);

    const computingCards = shallowRef();

    // 

    const helpers = {
        getItemById: memoize(function (id) {
            return props.items.find(({ uuid }) => uuid === id);
        }),
        exportEvent: async function (driver, { _start, _end, id }) {
            const item = this.getItemById(id);

            if (!item) {
                return;
            }

            const calendarEvent = {
                title: item.contentTitle,
                start: _start,
                end: _end,
                location: item?.venue,
                allDay: Boolean(item.allDay),
                rRule: ['google', 'ics'].includes(driver) && item.repeat ? rrule.eventScheduleItemToRRule(item, locale.dayjs).toString().split(`\n`)[1].replace('RRULE:', '') : undefined
            };

            if (driver === 'ics') {
                const temp = document.createElement('a');
                temp.href = calendarLink.ics(calendarEvent);
                temp.download = calendarEvent.title;
                temp.click();
            }
            else {
                window.open(calendarLink[driver](calendarEvent)).focus();
            }
        },
        share: async function (itemTitle, itemId, cardData) {
            const temp = useShare({});

            return temp.share({
                title: itemTitle,
                text: itemTitle,
                url: route('app-site.events.schedule', [props.eventId]) + '?item=' + itemId,
            });
        },
        getTimeDescription: function (allDay, start, end, isMultiDay) {
            return locale.formatDatetime(start, 'short', props.timezone) + ' - ' + locale.formatDatetime(end, 'short', props.timezone);
        },
        getGroupIndex: function (groups, date) {

            const dateTimestamp = date.startOf('day').valueOf();
            const currentIndex = groups.findIndex((x) => x.dateTimestamp === dateTimestamp);

            if (currentIndex !== -1) {
                return currentIndex;
            }

            const newLength = groups.push({
                dateTimestamp: dateTimestamp,
                items: []
            });

            return newLength - 1;
        },
        applyFilters: function (currentScheduleItem) {

            if (typesFilter.value?.length) {

                if (!currentScheduleItem?.type) {
                    return false;
                }

                if (!typesFilter.value.includes(currentScheduleItem.type)) {
                    return false;
                }
            }


            if (venuesFilter.value?.length) {

                if (!currentScheduleItem?.venue) {
                    return false;
                }

                if (!venuesFilter.value.includes(currentScheduleItem.venue)) {
                    return false;
                }
            }

            if (hostsFilter.value?.length) {

                if (!currentScheduleItem?.hosts) {
                    return false;
                }

                if (!hostsFilter.value.some((x) => currentScheduleItem.hosts.includes(x))) {
                    return false;
                }
            }

            if (speakersFilter.value?.length) {

                if (!currentScheduleItem?.partners) {
                    return false;
                }

                if (!speakersFilter.value.some((x) => (currentScheduleItem?.partners || []).map(({ uuid }) => uuid).includes(x))) {
                    return false;
                }
            }

            if (tagsFilter.value?.length) {

                if (!currentScheduleItem?.tags) {
                    return false;
                }

                if (!tagsFilter.value.some((x) => currentScheduleItem.tags.includes(x))) {
                    return false;
                }
            }

            if (favoritesFilter.value === true) {
                if (!props.userFavorites.includes(currentScheduleItem.uuid)) {
                    return false;
                }
            }

            return true;
        },
        getUserTicketId: memoize(function (ticketTypeId) {

            for (const participantId in props.userTickets) {
                if (props.userTickets[participantId] === ticketTypeId) {
                    return participantId;
                }
            }

            return;
        }),
    };

    const computedCards = computedAsync(
        async function () {

            // console.log('computing');

            let focused = 0;

            const allGroups = props.items

                // filters
                .filter(helpers.applyFilters)

                // grouping 
                .reduce(function (accumulator, currentScheduleItem) {

                    let requiredFocus = props.itemWithFocus && props.itemWithFocus === currentScheduleItem.uuid;

                    const item = JSON.parse(JSON.stringify(({
                        id: currentScheduleItem.uuid,
                        title: currentScheduleItem.contentTitle.toUpperCase(),
                        button: compute((ticketId, { label, label2 }) => {
                            if (!ticketId) {
                                return;
                            }

                            const userTicketId = helpers.getUserTicketId(ticketId);

                            if (userTicketId) {
                                return {
                                    to: route('app-site.user.menu.tickets.show', [userTicketId]),
                                    prependIcon: 'mdi-open-in-new',
                                    text: label2 || 'ver ingresso',
                                };
                            }
                            else {
                                return {
                                    to: route('app-site.events.show', [props.eventId, ticketId]),
                                    text: label || 'ingressos',
                                };
                            }
                        }, currentScheduleItem?.ticketType?.uuid, currentScheduleItem?.buttonOptions || {}),
                        image: currentScheduleItem?.image?.large_square,
                        // originalImage: currentScheduleItem?.image?.original,
                        type: currentScheduleItem?.type,
                        venue: currentScheduleItem?.venue,
                        hosts: toRaw(currentScheduleItem?.hosts || []),
                        tags: toRaw(currentScheduleItem?.tags || []),
                        speakers: currentScheduleItem?.partners || [],
                    })));

                    const startDate = locale.dayjs(currentScheduleItem.startAt);
                    const endDate = locale.dayjs(currentScheduleItem.endAt);
                    const duration = locale.dayjs.duration(startDate.diff(endDate));

                    if (!currentScheduleItem.repeat) {

                        const index = helpers.getGroupIndex(accumulator, startDate);

                        accumulator[index].items.push(Object.assign(item, {
                            _start: startDate.valueOf(),
                            _end: endDate.valueOf(),
                            timeDescription: helpers.getTimeDescription(currentScheduleItem.allDay, startDate, endDate, Math.abs(duration.asDays()) >= 1),

                            focus: requiredFocus,
                        }));

                        if (requiredFocus) {
                            focused++;
                        }
                    }
                    else {

                        // recurrence
                        rrule.all(currentScheduleItem, locale.dayjs).forEach((xOccurrence) => {

                            const startDate = locale.dayjs(xOccurrence);
                            const endDate = startDate.add(Math.abs(duration.asMilliseconds()), 'milliseconds');
                            const index = helpers.getGroupIndex(accumulator, startDate);

                            const focus = requiredFocus && focused === 0;

                            accumulator[index].items.push(Object.assign({
                                _start: xOccurrence.valueOf(),
                                _end: endDate.valueOf(),
                                timeDescription: helpers.getTimeDescription(currentScheduleItem.allDay, startDate, endDate, Math.abs(duration.asDays()) >= 1),

                                focus,
                            }, item));

                            if (focus) {
                                focused++;
                            }
                        });
                    }

                    return accumulator;

                }, []);

            // console.log('computed', allGroups);

            // sorting
            return sortBy(allGroups.map(function (xDate) {
                xDate.items = sortBy(xDate.items, [({ _start }) => _start]);

                return xDate;
            }), [({ dateTimestamp }) => dateTimestamp]);
        },
        [],
        {
            shallow: true,
            onError: console.error,
            evaluating: computingCards
        }
    );

</script>


<template>
    <v-container :event-id="eventId">

        <template v-if="items.length">

            <v-row justify="center" dense>
                <v-col cols="12">
                    <v-row justify="start" dense>
                        <v-col v-if="allTypes.length > 1" cols="12" lg="3" xl="2">
                            <v-select label="Tipo" density="compact" v-model="typesFilter" :items="allTypes" multiple
                                hide-details clearable></v-select>
                        </v-col>
                        <v-col v-if="allVenues.length > 1" cols="12" lg="3" xl="2">
                            <v-select label="Local" density="compact" v-model="venuesFilter" :items="allVenues" multiple
                                hide-details clearable></v-select>
                        </v-col>
                        <v-col v-if="allHosts.length > 1" cols="12" lg="3" xl="2">
                            <v-select label="Anfitriões" density="compact" v-model="hostsFilter" :items="allHosts"
                                multiple hide-details clearable></v-select>
                        </v-col>
                        <v-col v-if="speakers.length > 1" cols="12" lg="3" xl="2">
                            <v-select label="Palestrantes" density="compact" v-model="speakersFilter" :items="speakers"
                                multiple hide-details clearable item-title="name" item-value="uuid">
                            </v-select>
                        </v-col>
                        <v-col v-if="allTags.length > 1" cols="12" lg="3" xl="2">
                            <v-select label="Tags" density="compact" v-model="tagsFilter" :items="allTags" multiple
                                hide-details clearable></v-select>
                        </v-col>
                        <v-col v-if="userFavorites.length" cols="12" lg="3" xl="2" class="text-center">
                            <v-checkbox label="Favoritos" v-tooltip="'Mostrar apenas favoritos'" class="d-inline-block"
                                hide-details density="compact" false-icon="mdi-heart-outline" color="pink-accent-4"
                                true-icon="mdi-heart" v-model="favoritesFilter"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-spacer class="my-10"></v-spacer>

            <template v-if="computingCards">
                <v-row justify="center">
                    <v-col cols="12" class="text-center">
                        <v-progress-circular indeterminate size="x-small" width="1" class="me-3"></v-progress-circular>
                        <span>
                            carregando atividades da agenda...
                        </span>
                    </v-col>
                    <v-col v-for="x in 5" cols="12">
                        <v-skeleton-loader loading type="list-item-avatar-three-line" class="mb-5"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </template>

            <v-row v-else justify="center">

                <template v-for="xCardGroup in computedCards" :key="xCardGroup.dateTimestamp">

                    <v-col v-if="xCardGroup.items.length" cols="12">
                        <span class="font-weight-bold text-grey-darken-3 text-h6 text-uppercase">
                            {{ $locale.formatDate(xCardGroup.dateTimestamp, 'full') }}
                        </span>
                    </v-col>

                    <v-col v-for="(xCard, xCardIndex) in xCardGroup.items" :key="xCardIndex" cols="12"
                        align-self="center">

                        <v-card flat :border="$vuetify.display.mobile" color="grey-lighten-4" class="mb-3">

                            <template v-if="$vuetify.display.mobile">
                                <v-icon :icon="userFavorites.includes(xCard.id) ? 'mdi-heart' : 'mdi-heart-outline'"
                                    color="pink-accent-4" role="button" class="position-absolute pa-5 rounded-circle"
                                    style="right:1px;top:1px;z-index: 999;" @click="() => $emit('toggleLike', xCard.id)"
                                    :readonly="disableActions"></v-icon>
                            </template>
                            <template v-else>
                                <v-icon :icon="userFavorites.includes(xCard.id) ? 'mdi-heart' : 'mdi-heart-outline'"
                                    v-tooltip="userFavorites.includes(xCard.id) ? 'Remover dos favoritos' : 'Adicionar aos favoritos'"
                                    color="pink-accent-4" role="button" class="position-absolute pa-5 rounded-circle"
                                    style="right: 5px;top:5px;z-index: 999;" size="large"
                                    @click="() => $emit('toggleLike', xCard.id)" :readonly="disableActions"></v-icon>
                            </template>

                            <v-row align="center" dense>

                                <v-col cols="12" lg="6" xl="7">

                                    <v-row justify="center" align="center">
                                        <v-col cols="8" lg="4">

                                            <v-img v-if="$vuetify.display.mobile" :src="xCard?.image || $imgPlaceholder"
                                                style="opacity: 0.9;" class="rounded-lg mt-1 border"
                                                :aspect-ratio="1 / 1"></v-img>

                                            <v-img v-else :src="xCard?.image || $imgPlaceholder" style="opacity: 0.9;"
                                                :aspect-ratio="1 / 1" class="rounded-s-lg border-s"
                                                :title="!xCard?.image ? undefined : 'Imagem em processamento'"></v-img>
                                        </v-col>

                                        <v-col cols="12" lg="8">
                                            <v-chip v-if="xCard?.type" :text="xCard.type.toUpperCase()"
                                                class="font-weight-medium" variant="text"></v-chip>

                                            <v-dialog :model-value="xCard.focus" :fullscreen="$vuetify.display.mobile"
                                                width="auto">
                                                <template #activator="{ props }">
                                                    <v-card-title :="props" class="card-title mb-lg-3 pt-0">
                                                        {{ xCard.title }}
                                                    </v-card-title>
                                                </template>

                                                <template #default="{ isActive }">
                                                    <v-sheet width="1000" max-width="100%">

                                                        <v-row no-gutters>

                                                            <v-col
                                                                :cols="$vuetify.display.mobile ? 12 : (xCard?.image ? 10 : 12)">

                                                                <v-card variant="text">

                                                                    <template #append>
                                                                        <v-btn icon="mdi-close"
                                                                            :size="$vuetify.display.mobile ? 'small' : undefined"
                                                                            @click="() => { isActive.value = false; }"></v-btn>
                                                                    </template>

                                                                    <template #title>
                                                                        <h4
                                                                            class="text-h4 text-center font-weight-bold pt-5 text-wrap-pretty">
                                                                            {{ xCard.title.toUpperCase() }}
                                                                        </h4>
                                                                    </template>

                                                                    <v-row justify="center" dense no-gutters>
                                                                        <v-col cols="12" xl="10">

                                                                            <v-divider class="my-3"></v-divider>

                                                                            <template
                                                                                v-if="helpers.getItemById(xCard.id)?.contentDescription">

                                                                                <v-card-text class="px-5">

                                                                                    <div tag="div"
                                                                                        v-html="helpers.getItemById(xCard.id)?.contentDescription || ''"
                                                                                        style="max-height: 300px;overflow-y:auto;"
                                                                                        class="mt-3 mb-5">
                                                                                    </div>

                                                                                </v-card-text>

                                                                                <v-divider class="my-3"></v-divider>

                                                                            </template>

                                                                            <v-card-text class="px-5">

                                                                                <v-row>
                                                                                    <v-col v-if="xCard.timeDescription"
                                                                                        cols="12" lg="3" xl="6">
                                                                                        <v-chip variant="text"
                                                                                            color="black"
                                                                                            prepend-icon="mdi-clock"
                                                                                            v-tooltip="`Horários e datas`">
                                                                                            {{ xCard.timeDescription }}
                                                                                        </v-chip>
                                                                                    </v-col>
                                                                                    <v-col v-if="xCard?.venue" cols="12"
                                                                                        lg="3" xl="6">
                                                                                        <v-chip variant="text"
                                                                                            color="black"
                                                                                            prepend-icon="mdi-map-marker"
                                                                                            v-tooltip="`Local`">
                                                                                            {{ xCard.venue }}
                                                                                        </v-chip>
                                                                                    </v-col>
                                                                                    <v-col v-if="xCard.speakers?.length"
                                                                                        cols="12" lg="3" xl="6">

                                                                                        <v-icon
                                                                                            v-if="xCard?.speakers?.length"
                                                                                            icon="mdi-account-multiple"
                                                                                            class="me-2"></v-icon>

                                                                                        <v-menu
                                                                                            v-for="xSpeaker in (xCard.speakers || [])"
                                                                                            :key="xSpeaker?.uuid"
                                                                                            close-on-content-click
                                                                                            location="top" offset="3"
                                                                                            open-on-hover>

                                                                                            <template
                                                                                                #activator="{ props }">
                                                                                                <v-chip :="props"
                                                                                                    variant="tonal"
                                                                                                    color="primary"
                                                                                                    class="mb-1"
                                                                                                    :prepend-icon="xSpeaker?.image ? undefined : `mdi-account-multiple`"
                                                                                                    :prepend-avatar="xSpeaker?.image?.small || xSpeaker?.image?.original"
                                                                                                    :text="xSpeaker.name"
                                                                                                    role="button">
                                                                                                </v-chip>
                                                                                            </template>

                                                                                            <template
                                                                                                #default="{ isActive }">
                                                                                                <v-banner
                                                                                                    max-width="600"
                                                                                                    class=""
                                                                                                    rounded="lg"
                                                                                                    elevation="10">
                                                                                                    <template
                                                                                                        v-if="xSpeaker?.image"
                                                                                                        #prepend>
                                                                                                        <v-avatar
                                                                                                            :image="xSpeaker?.image?.medium || xSpeaker?.image?.original"
                                                                                                            size="150"></v-avatar>
                                                                                                    </template>
                                                                                                    <template #text>
                                                                                                        <span
                                                                                                            class="text-h6 font-weight-bold">
                                                                                                            {{
                                                                                                                xSpeaker.name
                                                                                                            }}
                                                                                                        </span>

                                                                                                        <template
                                                                                                            v-if="xSpeaker?.about">
                                                                                                            <br>
                                                                                                            <p
                                                                                                                v-html="xSpeaker?.about || ''">
                                                                                                            </p>
                                                                                                        </template>
                                                                                                    </template>
                                                                                                </v-banner>
                                                                                            </template>
                                                                                        </v-menu>

                                                                                    </v-col>
                                                                                    <v-col v-if="xCard.tags?.length"
                                                                                        cols="12" lg="3" xl="6">
                                                                                        <v-chip variant="text"
                                                                                            color="black"
                                                                                            prepend-icon="mdi-tag"
                                                                                            v-tooltip="`Tags`">
                                                                                            {{ xCard.tags.join(', ') }}
                                                                                        </v-chip>
                                                                                    </v-col>

                                                                                </v-row>
                                                                            </v-card-text>

                                                                            <v-divider class="my-3"></v-divider>

                                                                            <v-card-text class="mb-1">

                                                                                <v-btn v-if="xCard?.button"
                                                                                    color="primary"
                                                                                    prepend-icon="mdi-ticket-outline"
                                                                                    class="mb-3" block
                                                                                    :readonly="disableActions"
                                                                                    :="xCard.button"></v-btn>

                                                                                <v-menu>
                                                                                    <template #activator="{ props }">
                                                                                        <v-btn :="props"
                                                                                            text="Adicionar a agenda"
                                                                                            prepend-icon="mdi-plus"
                                                                                            block color="primary"
                                                                                            variant="tonal" size="small"
                                                                                            class="mb-3"></v-btn>
                                                                                    </template>

                                                                                    <v-list density="compact"
                                                                                        variant="text" class="py-0">
                                                                                        <v-list-item
                                                                                            title="Google Calendar"
                                                                                            @click="() => helpers.exportEvent('google', xCard)"
                                                                                            prepend-icon="mdi-google"
                                                                                            base-color="black"></v-list-item>
                                                                                        <v-list-item title="Yahoo"
                                                                                            @click="() => helpers.exportEvent('yahoo', xCard)"
                                                                                            prepend-icon="mdi-yahoo"
                                                                                            base-color="deep-purple-darken-3"></v-list-item>
                                                                                        <v-list-item title="Outlook"
                                                                                            @click="() => helpers.exportEvent('outlook', xCard)"
                                                                                            prepend-icon="mdi-microsoft-outlook"
                                                                                            base-color="blue-darken-4"></v-list-item>
                                                                                        <v-list-item title="Office 365"
                                                                                            @click="() => helpers.exportEvent('office365', xCard)"
                                                                                            prepend-icon="mdi-microsoft-office"
                                                                                            base-color="red-darken-1"></v-list-item>
                                                                                        <v-list-item
                                                                                            title="AOL Calendar"
                                                                                            @click="() => helpers.exportEvent('aol', xCard)"
                                                                                            prepend-icon="mdi-open-in-new"
                                                                                            base-color="blue-accent-4"></v-list-item>
                                                                                        <v-list-item title="Arquivo ICS"
                                                                                            @click="() => helpers.exportEvent('ics', xCard)"
                                                                                            prepend-icon="mdi-file-download"></v-list-item>
                                                                                    </v-list>
                                                                                </v-menu>

                                                                                <v-btn color="primary"
                                                                                    prepend-icon="mdi-share-variant"
                                                                                    text="Compartilhar" variant="tonal"
                                                                                    @click="() => helpers.share(xCard.title, xCard.id, xCard)"
                                                                                    block size="small"
                                                                                    class="mb-3"></v-btn>

                                                                                <v-btn v-if="false" color="black"
                                                                                    prepend-icon="mdi-close"
                                                                                    text="Fechar"
                                                                                    @click="() => { isActive.value = false; }"
                                                                                    block size="small"
                                                                                    variant="tonal"></v-btn>

                                                                            </v-card-text>
                                                                        </v-col>
                                                                    </v-row>

                                                                </v-card>

                                                            </v-col>

                                                            <v-col v-if="xCard?.image && !$vuetify.display.mobile"
                                                                cols="2">
                                                                <v-img :src="xCard?.image || $imgPlaceholder"
                                                                    style="filter: opacity(0.75) blur(1px);"
                                                                    class="border-s" :aspect-ratio="1 / 1" height="100%"
                                                                    cover
                                                                    gradient="to left, rgba(0,0,0,0), rgba(0,0,0,0.5)"></v-img>
                                                            </v-col>

                                                        </v-row>
                                                    </v-sheet>
                                                </template>
                                            </v-dialog>

                                            <div class="mx-3 mb-xl-5 ps-1">
                                                <span v-for="(xTag, xTagIndex) in xCard.tags">
                                                    <small role="button" class="">{{ xTag }}</small>
                                                    <span v-if="(xTagIndex + 1) !== xCard.tags.length"
                                                        class="px-1">&#x2022;</span>
                                                </span>
                                            </div>

                                            <div class="mx-3">

                                                <v-chip v-if="xCard?.timeDescription" density="comfortable"
                                                    prepend-icon="mdi-clock" color="black" role="button"
                                                    :text="xCard?.timeDescription" variant="tonal"
                                                    class="me-1 mb-1"></v-chip>

                                                <v-chip v-if="xCard?.venue" density="comfortable"
                                                    prepend-icon="mdi-map-marker" color="black" role="button"
                                                    :text="xCard.venue" variant="tonal" class="me-1 mb-1"></v-chip>

                                            </div>

                                        </v-col>

                                    </v-row>
                                </v-col>

                                <v-col v-if="xCard?.button" cols="12" lg="6" xl="5">
                                    <v-row align="center" justify="center">
                                        <v-col cols="auto" justify="center">
                                            <v-btn color="primary" prepend-icon="mdi-ticket-outline"
                                                class="mb-3 mb-xl-0" :="xCard.button"
                                                :readonly="disableActions"></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>




                            </v-row>
                        </v-card>
                    </v-col>
                </template>

            </v-row>

        </template>

        <template v-else>
            <v-alert color="primary" text="Agenda vazia" icon="mdi-information"></v-alert>
        </template>



    </v-container>
</template>


<style lang="scss" scoped>

    :deep(.card-title:hover) {

        text-decoration: underline;
        cursor: pointer;
    }

    :deep(.tags-chip) {
        // ::after {
        //     content: '';
        //     display: inline-block;
        //     width: 3px;
        //     height: 3px;
        //     background-color: black;
        //     position: absolute;
        //     right: -40px;
        // }
    }

</style>