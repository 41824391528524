import { useClipboard } from "@vueuse/core";
import { Mask } from 'maska';
import { truncate as truncateLodash } from "lodash";
import { notify } from "./Helpers/Flash";

const clipboard = function (element, { value }) {
    // const initialText = element.textContent;

    element.setAttribute('title', 'Clique para copiar');

    const { copy } = useClipboard({ source: value });

    element.addEventListener('click', async () => {
        await copy();

        notify().info('Copiado');
    });
};

const maskFormat = function (element, { value: mask }) {

    if (!mask || !element.textContent) {
        return;
    }

    const result = (new Mask({ mask })).masked(element.textContent);

    if (!result) {
        return;
    }

    element.innerText = result;
};

const truncate = function (element, { value: length }) {
    element.innerText = truncateLodash(element.innerText, { length });
};

export {
    clipboard,
    maskFormat,
    truncate,
};
