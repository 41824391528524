<script setup>

    import { useInfinitePaginator } from '../../../../../../../../resources/vue/Composables';
    import { useDisplay } from 'vuetify/lib/framework.mjs';

    const display = useDisplay();

    const actionSlotName = display.mobile.value ? `actions` : `append`;

    const pagination = useInfinitePaginator({
        propName: 'orders',
    });

</script>

<template>
    <v-container style="max-width: 1400px;min-height: 90%;" class="mx-auto">
        <v-breadcrumbs :items="[{ title: 'Início', to: $route('app-site.home') }, 'Pedidos']"
            class="mb-5"></v-breadcrumbs>

        <h1 class="text-h4">Pedidos realizados</h1>

        <v-infinite-scroll @load="pagination.loadMore">

            <v-card v-for="(xItem, xItemIndex) in pagination.items" :key="xItemIndex" flat border class="pa-3 mb-3">

                <template #title>
                    <span class="text-wrap text-1">
                        Pedido#{{ xItem.uuid }}
                    </span>
                </template>

                <template #text>

                    <v-list>
                        <v-list-item title="Status">
                            <template #append>
                                <v-chip :text="$t(xItem.invoiceStatus.meta.name).toUpperCase()"
                                    :color="xItem.invoiceStatus.meta.mdColor" size="small" variant="text"
                                    class="font-weight-bold "></v-chip>
                            </template>
                        </v-list-item>
                        <v-list-item title="Total">
                            <template #append>
                                {{ $locale.formatCurrency(xItem.invoiceTotalAmount) }}
                            </template>
                        </v-list-item>
                        <v-list-item title="Data">
                            <template #append>
                                {{ $locale.formatDatetime(xItem.createdAt) }}
                            </template>
                        </v-list-item>
                    </v-list>
                </template>

                <template #[actionSlotName]>

                    <v-btn :to="$route('app-site.user.menu.orders.show', [xItem.uuid])" block variant="flat"
                        color="primary" text="Detalhes do pedido" class="me-5"
                        :size="$vuetify.display.mobile ? 'small' : undefined" prepend-icon="mdi-open-in-new"></v-btn>
                </template>


            </v-card>

        </v-infinite-scroll>
    </v-container>
</template>
