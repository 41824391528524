<script setup>
    import { router, useForm } from '@inertiajs/vue3';
    import { useQRCode } from '@vueuse/integrations/useQRCode';
    import { useLoading } from '../../../../../../../../resources/vue/Composables';
    import { onMounted, computed, inject, shallowRef } from 'vue';

    const props = defineProps({
        order: Object,
        orderEvents: Array,
        orderStatus: Object,
        isPix: Boolean,
        fullRefundEligible: Boolean, // boolean or undefined
        fullRefundEligibleReason: String,
        errors: Object,
        documentTypeEnum: Object,
        bankIdEnum: Object,
        bankAccountTypeEnum: Object,
        _user: Object,
        tickets: Array,
    });

    const locale = inject('locale');

    const pixQrCode = computed(() => {
        if (!props.order.isPix) {
            return null;
        }

        return useQRCode(props.order.invoiceTicketCode, {
            width: 400,
            margin: 2,
        });
    });

    const payment = computed(() => {

        if (!props.order.paymentMethod) {
            return {
                title: 'Sem pagamento',
                subtitle: ''
            }
        }

        if (props.order.isCreditCard) {
            return {
                title: (() => {

                    if (props.order?.invoice_used_credit_card_last_digits) {
                        return `${locale.get(props.order.paymentMethod.method.meta.name)} com final ${props.order?.invoice_used_credit_card_last_digits}`;
                    }

                    return locale.get(props.order.paymentMethod.method.meta.name);
                })(),

                subtitle: 'Cartão de crédito usado',

                image: props.order?.invoiceUsedCreditCardBrand?.meta?.image || props.order.paymentMethod?.method?.meta?.coloredImage,
            }
        }

        return {
            title: locale.get(props.order.paymentMethod.method.meta.name),
            subtitle: 'Meio de pagamento',
            image: props.order.paymentMethod?.method?.meta?.coloredImage,
        }
    });

    const interval = shallowRef(3000);

    const reload = () => {
        interval.value = interval.value + 1000;
        router.reload({
            onSuccess: function ({ props: { orderStatus } }) {
                onLoad(orderStatus);
            }
        });
    };

    const onLoad = (orderStatus) => {
        if (!orderStatus?.loading || interval.value >= 7000) {
            return;
        }

        setTimeout(reload, interval.value);
    }

    onMounted(() => {
        onLoad(props.orderStatus);
    });

    const orderRefundStoreForm = useForm({
        holder_full_name: props._user?.name || null,
        holder_document_type: props._user?.documentType || null,
        holder_document_number: props._user?.documentNumber || null,
        pix_key: null,
        bank_account_bank: null,
        bank_account_type: null,
        bank_account_number: null,
        bank_account_number_digit: null,
        bank_account_agency: null,
        bank_account_agency_digit: null,
    });

    // 
</script>

<template>
    <v-container style="max-width: 1400px">
        <v-breadcrumbs :items="[
            { title: 'Início', to: $route('app-site.home') },
            {
                title: 'Pedidos',
                to: $route('app-site.user.menu.orders.index'),
            },
            'Pedido#' + order.uuid,
        ]" class="mb-5"></v-breadcrumbs>

        <v-card flat variant="text" class="mb-3">
            <template #title>
                <v-card-title>
                    <span>Pedido#<b>{{ order.uuid }}</b></span>

                    <v-chip :text="$locale.formatDatetime(order.createdAt)" class="ms-3" prepend-icon="mdi-calendar"
                        color="primary" variant="tonal"></v-chip>
                </v-card-title>
            </template>

            <template #append>
                <v-dialog max-width="100%" width="800" persistent :fullscreen="$vuetify.display.mobile">
                    <template #activator="{ props }">
                        <v-btn :="props" text="Cancelar pedido" prepend-icon="mdi-close-circle" size="small"
                            color="error" block></v-btn>
                    </template>

                    <template #default="{ isActive }">
                        <v-card class="px-md-5 py-3 text-wrap" :rounded="$vuetify.display.mobile ? 0 : undefined">
                            <template #append>
                                <v-btn icon="mdi-close" @click="() => isActive.value = false"></v-btn>
                            </template>

                            <template v-if="fullRefundEligible !== true" #title>
                                <v-card-title class="text-h5  text-wrap">
                                    Não é possível cancelar esse pedido.
                                </v-card-title>
                            </template>

                            <template v-else #title>
                                <v-card-title class="text-wrap">
                                    Cancelar e solicitar reembolso
                                </v-card-title>
                            </template>

                            <template v-if="fullRefundEligible === true && !order?.isCreditCard" #subtitle>
                                <v-card-subtitle class="text-h6 font-weight-bold text-wrap">
                                    Informe uma chave pix e conta bancária:
                                </v-card-subtitle>
                            </template>

                            <template v-if="fullRefundEligible">
                                <v-card-text>
                                    <x-form :form="orderRefundStoreForm"
                                        :action="$route('app-site.user.menu.orders.refund', [order.uuid])"
                                        :options="{ preserveState: false }" #="{ activatorProps }">

                                        <template v-if="order.isCreditCard">

                                            <v-card-text class="mb-2">
                                                <p class="font-weight-medium text-subtitle-1">
                                                    O reembolso será feito na fatura do cartão de crédito
                                                    <span v-if="order?.invoice_used_credit_card_last_digits">
                                                        com final {{ order?.invoice_used_credit_card_last_digits }}.
                                                    </span>
                                                    <span v-else>
                                                        .
                                                    </span>
                                                </p>
                                            </v-card-text>
                                        </template>

                                        <template v-else>

                                            <v-alert variant="tonal" color="primary" border closable density="compact">
                                                <p class="mb-1">
                                                    <b>Por que preciso informar esses dados?</b>
                                                </p>
                                                <p>Para pedidos pagos com <strong>BOLETO</strong> é necessário uma conta
                                                    bancária. </p>
                                                <p>Para pedidos pagos com <strong>PIX</strong> o reembolso será feito
                                                    para conta de origem mas em caso de problemas poderemos usar a conta
                                                    informada para efetuar o reembolso. </p>
                                            </v-alert>

                                            <div class="ps-5 my-3">
                                                <p><b>Atenção</b></p>
                                                <ul>
                                                    <li>
                                                        A conta informada deve pertencer a mesma pessoa que realizou
                                                        o
                                                        pedido.
                                                    </li>
                                                    <li>
                                                        Informe o nome <b>completo</b> do titular.
                                                    </li>
                                                    <li>
                                                        Confira os dados antes de enviar.
                                                    </li>
                                                </ul>
                                            </div>


                                            <v-divider class="my-5"></v-divider>

                                            <v-row dense>
                                                <v-col cols="12">
                                                    <v-text-field v-model="orderRefundStoreForm.holder_full_name"
                                                        :error-message="errors?.holder_full_name"
                                                        label="Nome completo do titular da conta"
                                                        :rules="[$requiredRule]"></v-text-field>

                                                </v-col>

                                                <v-col cols="12" lg="4">
                                                    <v-select v-model="orderRefundStoreForm.holder_document_type"
                                                        :items="documentTypeEnum" :item-title="(x) => $t(x.meta.name)"
                                                        :error-message="errors?.holder_document_type"
                                                        label="Tipo de documento" :rules="[$requiredRule]"></v-select>
                                                </v-col>

                                                <v-col cols="12" lg="8">
                                                    <v-text-field v-model="orderRefundStoreForm.holder_document_number"
                                                        :error-message="errors?.holder_document_number"
                                                        label="Número do documento do titular da conta"
                                                        :rules="[$requiredRule]"></v-text-field>
                                                </v-col>


                                                <v-col cols="12">
                                                    <v-text-field v-model="orderRefundStoreForm.pix_key"
                                                        :error-message="errors?.pix_key"
                                                        label="Chave PIX (Recomendamos o uso de chave aleatória)"
                                                        :rules="[$requiredRule]"
                                                        :hint="`Chave aleatória(RECOMENDADO), e-mail, telefone(ex: +55129...) ou CPF/CNPJ.`">
                                                    </v-text-field>
                                                </v-col>

                                            </v-row>

                                            <v-row dense>
                                                <v-col cols="12" lg="7">
                                                    <v-autocomplete v-model="orderRefundStoreForm.bank_account_bank"
                                                        :error-message="errors?.bank_account_bank" label="Banco"
                                                        :rules="[$requiredRule]" :items="bankIdEnum"
                                                        :item-title="(x) => x.value + ' - ' + $t(x.meta.friendlyName)"></v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" lg="5">
                                                    <v-select v-model="orderRefundStoreForm.bank_account_type"
                                                        :error-message="errors?.bank_account_type" label="Tipo de conta"
                                                        :rules="[$requiredRule]" :items="bankAccountTypeEnum"
                                                        :item-title="(x) => $t(x.meta.name).toUpperCase()"></v-select>
                                                </v-col>

                                                <v-col cols="12" lg="8">
                                                    <v-text-field v-model="orderRefundStoreForm.bank_account_number"
                                                        :error-message="errors?.bank_account_number"
                                                        label="Número da conta" :rules="[$requiredRule]"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="4">
                                                    <v-text-field
                                                        v-model="orderRefundStoreForm.bank_account_number_digit"
                                                        :error-message="errors?.bank_account_number_digit"
                                                        label="Dígito da conta" :rules="[$requiredRule]"></v-text-field>
                                                </v-col>

                                                <v-col cols="12" lg="8">
                                                    <v-text-field v-model="orderRefundStoreForm.bank_account_agency"
                                                        :error-message="errors?.bank_account_agency" label="Agência"
                                                        :rules="[$requiredRule]"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" lg="4">
                                                    <v-text-field
                                                        v-model="orderRefundStoreForm.bank_account_agency_digit"
                                                        :error-message="errors?.bank_account_agency_digit"
                                                        label="Dígito da agência"
                                                        :rules="[$requiredRule]"></v-text-field>
                                                </v-col>
                                            </v-row>

                                        </template>

                                        <v-btn :="activatorProps" text="Prosseguir com reembolso" block color="primary"
                                            prepend-icon="mdi-check" size="large" class="mt-10"></v-btn>
                                    </x-form>
                                </v-card-text>

                            </template>

                            <template v-else-if="!fullRefundEligible && fullRefundEligibleReason">
                                <v-card-text>
                                    <p>
                                        Motivo:
                                        <strong class="text-error text-decoration-underline">
                                            {{ fullRefundEligibleReason?.toUpperCase() }}
                                        </strong>
                                    </p>
                                </v-card-text>
                            </template>

                            <v-card-text class="border-t">
                                <p>Sobre o processo de cancelamento/reembolso de um pedido:</p>

                                <ul class="mt-3 mx-5">
                                    <li class="pb-5">
                                        <p>
                                            <b class="font-weight-bold">
                                                Para ingressos de eventos:
                                            </b>
                                            <span>
                                                O processo de reembolso será iniciado se a compra do ingresso
                                                atender aos requisitos abaixo:
                                            </span>
                                        </p>

                                        <ul class="ms-5 mt-3">
                                            <li>Se passaram no máximo 7 dias desde de a compra do ingresso</li>
                                            <li>O evento ainda <b>NÃO</b> teve início.</li>
                                        </ul>
                                    </li>

                                    <li class="pb-5">
                                        <b class="font-weight-bold">Prazo para reembolso:</b>
                                        <span>
                                            para pedidos pagos com
                                            PIX ou boleto o prazo é de
                                            até 7 dias. Para cartão de
                                            crédito o valor estará disponível na fatura atual ou na próxima.
                                        </span>
                                    </li>

                                    <li class="pb-5">
                                        <b class="font-weight-bold">Não se preocupe:</b>
                                        <span>
                                            após solicitar o cancelamento você receberá mais detalhes e/ou
                                            instruções
                                            via e-mail. Se encontrar
                                            problemas ou quiser tirar alguma dúvida entre em contato conosco
                                            pelo
                                            e-mail
                                            <x-mail :mail="$page.props._global.email"></x-mail>.
                                        </span>
                                    </li>
                                </ul>

                            </v-card-text>
                        </v-card>

                    </template>
                </v-dialog>
            </template>
        </v-card>

        <v-row>
            <v-col lg="8">
                <v-alert v-if="orderStatus" :icon="orderStatus.icon" :color="orderStatus.color" variant="flat"
                    class="mb-5" border="start">
                    <template #text>
                        {{ orderStatus.text }}
                    </template>

                    <template v-if="orderStatus?.loading && interval < 6000" #prepend>
                        <v-progress-circular indeterminate></v-progress-circular>
                    </template>

                    <template v-if="order.isUnpaid && order.hasInvoice" #append>
                        <v-dialog width="auto" persistent>
                            <template #activator="{ props }">
                                <v-btn color="primary" text="Ver fatura" prepend-icon="mdi-arrow-expand"
                                    :="props"></v-btn>
                            </template>

                            <template #default="{ isActive }">
                                <v-card width="800" title="Fatura a pagar">

                                    <template #append>
                                        <v-btn icon="mdi-close" @click="() => isActive.value = false"></v-btn>
                                    </template>

                                    <v-card-text v-if="order.isPix">

                                        <p class="mb-3">
                                            Escaneie o <b>QR code</b> abaixo com
                                            o aplicativo do seu banco para
                                            efetuar o pagamento ou então use o código <b>Pix copia e cola</b> .

                                            <span v-if="order?.invoiceExpireAt">
                                                Você tem
                                                até
                                                {{
                                                    $locale.formatDatetime(
                                                        order.invoiceExpireAt
                                                    )
                                                }}
                                                para realizar esse pagamento.
                                            </span>
                                        </p>

                                        <v-img :src="pixQrCode.value" :width="400" class="mx-auto mb-3"></v-img>

                                        <x-input-copyable :content="order.invoiceTicketCode" #="{ activatorProps }">
                                            <v-btn :="activatorProps" text="Pix copia e cola" size="small"
                                                color="primary"></v-btn>
                                        </x-input-copyable>


                                        <v-btn color="primary" class="mb-3" prepend-icon="mdi-check" block text="Feito"
                                            @click="() => router.visit($page.url, { preserveState: false })"></v-btn>
                                    </v-card-text>
                                    <v-card-text v-else-if="order.isBoleto">

                                        <p class="mb-3">
                                            Clique no botão abaixo para
                                            acessar o seu boleto em <b>PDF</b> ou então copie o <b>código de barras</b>.

                                            <span v-if="order.invoiceExpireAt">
                                                Você tem
                                                até
                                                {{
                                                    $locale.formatDatetime(
                                                        order.invoiceExpireAt
                                                    )
                                                }}
                                                para realizar esse pagamento.
                                            </span>
                                        </p>

                                        <v-btn color="primary" prepend-icon="mdi-barcode" text="Ver boleto"
                                            :href="order?.invoiceTicketUrl || $route('app-site.user.menu.orders.show-invoice', [order.uuid])"
                                            target="_blank" class="mb-3" block></v-btn>

                                        <x-input-copyable :content="order.invoiceTicketCode" #="{ activatorProps }"
                                            hide-details>
                                            <v-btn :="activatorProps" text="Código de barras" size="small"
                                                color="primary"></v-btn>
                                        </x-input-copyable>


                                        <v-alert color="warning" class="my-5" variant="tonal" density="compact"
                                            text="É possível que o boleto leve até 20 minutos para ficar disponível em alguns bancos."
                                            closable></v-alert>

                                        <v-btn color="primary" prepend-icon="mdi-check" block text="Feito"
                                            @click="() => router.visit($page.url, { preserveState: false })"></v-btn>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-dialog>

                    </template>
                </v-alert>

                <v-card flat border class="pa-5">
                    <v-card-title>Detalhes do pagamento</v-card-title>

                    <v-card-text>
                        <v-list>
                            <v-list-item class="mb-3">
                                <v-list-item-title class="font-weight-bold">
                                    {{ payment.title.toUpperCase() }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                    {{ payment.subtitle }}
                                </v-list-item-subtitle>

                                <template v-if="payment.image" #prepend>
                                    <v-img :src="payment.image" width="50" :aspect-ratio="16 / 9" class="me-5"></v-img>
                                </template>
                            </v-list-item>

                            <v-list-item v-if="order.invoiceInstallments" class="mb-3">
                                <v-list-item-title class="font-weight-bold">
                                    {{ order.invoiceInstallments === 1 ? 'À vista' : `em ${order.invoiceInstallments}x` }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                    Parcelas
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                    <v-divider class="my-3"></v-divider>

                    <v-card-subtitle>Histórico</v-card-subtitle>

                    <v-card-text>
                        <x-timeline-events :events="orderEvents" side="end">
                        </x-timeline-events>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="4">
                <v-card flat border class="pa-3 mb-5" title="Resumo do pedido">
                    <v-card-text>
                        <v-list variant="text">
                            <v-list-item v-for="xItem in order.items" :key="xItem.uuid" class="mb-3">
                                <template #title>
                                    <v-list-item-title class="text-uppercase">
                                        {{ xItem.name }}
                                        <template v-if="xItem.product">
                                            (x{{ xItem.quantity }})
                                        </template>
                                    </v-list-item-title>
                                </template>

                                <template #subtitle>
                                    <v-list-item-subtitle>
                                    </v-list-item-subtitle>
                                </template>

                                <template #prepend>
                                </template>

                                <template #append>
                                    <span :class="[xItem.debit ? '' : 'text-success']">
                                        {{ $locale.formatCurrency(xItem.finalAmount) }}
                                    </span>
                                </template>
                            </v-list-item>

                            <v-divider class="my-3"></v-divider>

                            <v-list-item>
                                <template #title>
                                    <v-list-item-title>
                                        Total (x{{ order.invoiceInstallments }})
                                    </v-list-item-title>
                                </template>

                                <template #append>
                                    <span>
                                        {{ $locale.formatCurrency(order.invoiceTotalAmount) }}
                                    </span>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>

                <v-card v-if="tickets.length" flat border class="mb-5" title="Ingressos">

                    <v-card-text>
                        <v-btn v-for="xTicket in tickets" color="primary" :text="'Entrada: ' + xTicket.ticketType.name"
                            class="mb-2" size="small" block prepend-icon="mdi-open-in-new"
                            :to="$route('app-site.user.menu.tickets.show', [xTicket.uuid])"></v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
