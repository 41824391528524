export default [
    {
        "country": "br",
        "mainLanguage": "pt_BR",
        "mainCurrency": "brl",
        "dateFormat": "DD/MM/YYYY",
        "timeFormat": "HH:mm:ss",
        "datetimeFormat": "DD/MM/YYYY HH:mm",
        "timezones": [
            "America/Rio_Branco",
            "America/Maceio",
            "America/Belem",
            "America/Manaus",
            "America/Bahia",
            "America/Fortaleza",
            "America/Sao_Paulo",
            "America/Fortaleza",
            "America/Cuiaba",
            "America/Campo_Grande",
            "America/Araguaina",
            "America/Boa_Vista"
        ],
        "mainTimezone": "America/Sao_Paulo",
        "personalDocumentTypes": [
            "br_cpf",
            "br_cnpj"
        ],
        "personalDocumentWithPhotoTypes": [
            "br_rg",
            "br_cnh"
        ],
        "phoneFormat": "br",
        "zipcodeFormat": "br",
        "ufs": [
            "BR-AC",
            "BR-AL",
            "BR-AP",
            "BR-AM",
            "BR-BA",
            "BR-CE",
            "BR-DF",
            "BR-ES",
            "BR-GO",
            "BR-MA",
            "BR-MT",
            "BR-MS",
            "BR-MG",
            "BR-PR",
            "BR-PB",
            "BR-PA",
            "BR-PE",
            "BR-PI",
            "BR-RN",
            "BR-RS",
            "BR-RJ",
            "BR-RO",
            "BR-RR",
            "BR-SC",
            "BR-SE",
            "BR-SP",
            "BR-TO"
        ],
        "decimalSeparator": ",",
        "thousandSeparator": ".",
        "translations": {
            "auth": {
                "failed": "Usuário e/ou senhas incorretos",
                "password": "A senha informada está incorreta.",
                "throttle": "Muitas tentativas de login. Tente novamente em {seconds} segundos.",
                "required": "Faça login para acessar essa página"
            },
            "enums": {
                "country": {
                    "br": "Brasil",
                    "us": "Estados Unidos"
                },
                "countryUf": {
                    "br_ac": "Acre",
                    "br_al": "Alagoas",
                    "br_ap": "Amapá",
                    "br_am": "Amazonas",
                    "br_ba": "Bahia",
                    "br_ce": "Ceará",
                    "br_df": "Distrito federal",
                    "br_es": "Espírito santo",
                    "br_go": "Goiás",
                    "br_ma": "Maranhão",
                    "br_mt": "Mato grosso",
                    "br_ms": "Mato grosso do sul",
                    "br_mg": "Minas gerais",
                    "br_pr": "Paraná",
                    "br_pb": "Paraíba",
                    "br_pa": "Pará",
                    "br_pe": "Pernambuco",
                    "br_pi": "Piauí",
                    "br_rn": "Rio grande do norte",
                    "br_rs": "Rio grande do sul",
                    "br_rj": "Rio de janeiro",
                    "br_ro": "Rondônia",
                    "br_rr": "Roraima",
                    "br_sc": "Santa catarina",
                    "br_se": "Sergipe",
                    "br_sp": "São paulo",
                    "br_to": "Tocantins"
                },
                "currency": {
                    "brl": "real",
                    "usd": "dólar"
                },
                "personalDocumentType": {
                    "br_cpf": "CPF",
                    "br_cnpj": "CNPJ"
                },
                "language": {
                    "pt_BR": "português - brasil",
                    "en_US": "inglês - estados unidos"
                },
                "purchaseChargeStatus": {
                    "paid": "pagamento realizado",
                    "partial_paid": "parcialmente paga",
                    "unpaid": "pagamento pendente",
                    "reversed": "estornada",
                    "partial_reversed": "estornada parcialmente",
                    "to_be_processed": "em processamento",
                    "process_failed": "com falha",
                    "canceled": "cancelada",
                    "expired": "expirada",
                    "with_error": "com erro",
                    "credit_card_refused": "cartão recusado"
                },
                "paymentMethod": {
                    "credit_card": "cartão de crédito",
                    "pix": "pix",
                    "boleto": "boleto",
                    "debit_card": "cartão de débito"
                },
                "subscriptionStatus": {
                    "trialing": "em trial",
                    "paid": "paga e ativa",
                    "unpaid": "pagamento pendente",
                    "ended": "finalizada",
                    "cancelled": "cancelada"
                },
                "personalDocumentWithPhotoType": {
                    "br_rg": "RG",
                    "br_cnh": "CNH"
                },
                "App\\Enums\\PersonalDocumentWithPhotoType": {
                    "br_rg": "RG",
                    "br_cnh": "CNH"
                },
                "eventStatus": {
                    "draft": "rascunho",
                    "published": "publicado",
                    "started": "acontecendo agora",
                    "ended": "encerrado",
                    "archived": "arquivado"
                },
                "buyable_item_type": {
                    "ticket": "ingresso",
                    "fee": "taxa administrativa",
                    "financing_fee": "juros do parcelamento",
                    "payment_method_discount": "desconto {paymentMethodName}"
                },
                "App\\Enums\\InvoiceStatus": {
                    "to_be_processed": "em processamento",
                    "process_failed": "com falha",
                    "with_error": "com erro",
                    "credit_card_refused": "cartão recusado",
                    "paid": "OK / pagamento realizado",
                    "unpaid": "pagamento pendente",
                    "partial_paid": "parcialmente pago",
                    "reversed": "estornado",
                    "partial_reversed": "parcialmente estornado",
                    "canceled": "cancelado",
                    "expired": "expirado",
                    "process_started": "em processamento"
                },
                "App\\Enums\\OrderRefundStatus": {
                    "successfully_processed": "processado com sucesso",
                    "process_started": "pendente",
                    "to_be_processed": "pendente",
                    "canceled": "cancelado",
                    "with_error": "com erro"
                },
                "App\\Enums\\BrazilianBankAccountType": {
                    "conta_deposito_poupanca": "conta poupança",
                    "conta_deposito_corrente": "conta corrente",
                    "conta_salario": "conta salário",
                    "conta_pagamento": "conta pagamento"
                },
                "App\\Enums\\CollaboratorRole": {
                    "event-accreditor": "Credenciador de eventos"
                }
            },
            "http-status": {
                "0": "Erro Desconhecido",
                "100": "Continuar",
                "101": "Mudando Protocolos",
                "102": "Processamento",
                "200": "OK",
                "201": "Criado",
                "202": "Aceito",
                "203": "Informações Não Autorizadas",
                "204": "Sem conteúdo",
                "205": "Redefinir Conteúdo",
                "206": "Conteúdo Parcial",
                "207": "Vários status",
                "208": "Já Reportado",
                "226": "Estou Usando",
                "300": "Múltiplas Escolhas",
                "301": "Movido Permanentemente",
                "302": "Movido Temporariamente",
                "303": "Ver Outros",
                "304": "Não Modificado",
                "305": "Use proxy",
                "307": "Redirecionamento Temporário",
                "308": "Redirecionamento Permanente",
                "400": "Requisição Inválida",
                "401": "Acesso não autorizado",
                "402": "Pagamento necessário",
                "403": "Acesso negado",
                "404": "Página Não Encontrada",
                "405": "Método Não Permitido",
                "406": "Não Aceitável",
                "407": "Autenticação De Proxy Necessária",
                "408": "Tempo Limite De Requisição",
                "409": "Conflito",
                "410": "Perdido",
                "411": "Comprimento Necessário",
                "412": "Pré-Condição Falhou",
                "413": "Carga Muito Grande",
                "414": "URI Muito Grande",
                "415": "Tipo De Mídia Não Suportado",
                "416": "Faixa Não Satisfatória",
                "417": "Falha Na Expectativa",
                "418": "Eu Sou Um Bule De Chá",
                "419": "A sessão ou o formulário expirou",
                "421": "Solicitação Mal Direcionada",
                "422": "Entidade não processável",
                "423": "Página/recurso bloqueado ou indisponível",
                "424": "Dependência Com Falha",
                "425": "Muito cedo",
                "426": "Atualização Necessária",
                "428": "Pré-Condição Necessária",
                "429": "Muitas Requisições, tente novamente mais tarde",
                "431": "Campos De Cabeçalho De Solicitação Muito Grandes",
                "444": "Conexão Fechada Sem Resposta",
                "449": "Repetir Com",
                "451": "Indisponível Por Motivos Legais",
                "499": "Solicitação fechada do cliente",
                "500": "Erro Interno Do Servidor",
                "501": "Não Implementado",
                "502": "Bad Gateway",
                "503": "Serviço temporariamente indisponível",
                "504": "Tempo Limite Do Gateway",
                "505": "Versão HTTP Não Suportada",
                "506": "Variante Também Negocia",
                "507": "Armazenamento Insuficiente",
                "508": "Loop Detectado",
                "509": "Limite de Banda Excedido",
                "510": "Não Estendido",
                "511": "Autenticação De Rede Necessária",
                "520": "Erro Desconhecido",
                "521": "Servidor Web Está Inativo",
                "522": "Tempo Limite Da Conexão",
                "523": "Origem É Inacessível",
                "524": "Ocorreu Um Tempo Limite",
                "525": "Falha No Handshake SSL",
                "526": "Certificado SSL Inválido",
                "527": "Railgun Erro",
                "598": "Erro de tempo limite de leitura da rede",
                "599": "Erro de tempo limite de conexão de rede"
            },
            "mail": {
                "greeting": "Olá, {mailRecipientName}",
                "error": "Oops",
                "no_error": "Olá!",
                "salutation": "Atenciosamente, equipe {mailSenderName}.",
                "action": "Se estiver com problemas para clicar no botão \"{actionText}\", copie e cole o URL abaixo em seu navegador:"
            },
            "messages": {
                "welcome": "Bem-vindo {name}",
                "logoff": "Desconectado com sucesso",
                "email-verified": "Sucesso! e-mail verificado.",
                "email-verification-send": "E-mail de verificação enviado. Confira sua caixa de entrada.",
                "password-updated": "Sucesso! sua senha foi atualizada",
                "created": "Sucesso! {resource} cadastrado",
                "updated": "Sucesso! {resource} atualizado",
                "deleted": "Sucesso! {resource} excluído"
            },
            "pagination": {
                "previous": "&laquo; Anterior",
                "next": "Próximo &raquo;"
            },
            "passwords": {
                "reset": "Sua senha foi redefinida!",
                "sent": "Enviamos seu link de redefinição de senha por e-mail!",
                "throttled": "Muitas tentativas. Aguarde antes de tentar novamente.",
                "token": "Este token de redefinição de senha é inválido.",
                "user": "Não encontramos um usuário com esse endereço de e-mail."
            },
            "resources": {
                "event": "evento|eventos",
                "event_ticket_type": "entrada|entradas",
                "event_ticket_batch": "lote de ingresso|lotes de ingressos"
            },
            "validation": {
                "accepted": "O campo {attribute} deve ser aceito.",
                "accepted_if": "O campo {attribute} deve ser aceito quando {other} é {value}.",
                "active_url": "O campo {attribute} não é uma URL válida.",
                "after": "O campo {attribute} deve ser uma data posterior a {date}.",
                "after_or_equal": "O campo {attribute} deve ser uma data posterior ou igual a {date}.",
                "alpha": "O campo {attribute} só pode conter letras.",
                "alpha_dash": "O campo {attribute} só pode conter letras, números e traços.",
                "alpha_num": "O campo {attribute} só pode conter letras e números.",
                "array": "O campo {attribute} deve ser uma matriz.",
                "before": "O campo {attribute} deve ser uma data anterior {date}.",
                "before_or_equal": "O campo {attribute} deve ser uma data anterior ou igual a {date}.",
                "between": {
                    "array": "O campo {attribute} deve ter entre {min} e {max} itens.",
                    "file": "O campo {attribute} deve ser entre {min} e {max} kb.",
                    "numeric": "O campo {attribute} deve ser entre {min} e {max}.",
                    "string": "O campo {attribute} deve ter entre {min} e {max} caracteres."
                },
                "boolean": "O valor selecionado para o campo {attribute} é inválido.",
                "confirmed": "O campo {attribute} deve ser igual ao campo confirmação de {attribute}.",
                "current_password": "A senha atual está incorreta",
                "date": "O campo {attribute} não é uma data válida.",
                "date_equals": "O campo {attribute} deve ser uma data igual a {date}.",
                "date_format": "O campo {attribute} não corresponde ao formato {format}.",
                "declined": "The campo {attribute} deve estar recusado.",
                "declined_if": "The campo {attribute} deve estar recusado quando {other} é {value}.",
                "different": "Os campos {attribute} e {other} devem ser diferentes.",
                "digits": "O campo {attribute} deve ter {digits} dígitos.",
                "digits_between": "O campo {attribute} deve ter entre {min} e {max} dígitos.",
                "dimensions": "O campo {attribute} tem dimensões de imagem inválidas.",
                "distinct": "O campo {attribute} campo tem um valor duplicado.",
                "doesnt_end_with": "O campo {attribute} não pode terminar com um dos seguintes valores: {values}.",
                "doesnt_start_with": "O campo {attribute} não pode começar com um dos seguintes valores: {values}.",
                "email": "O campo {attribute} deve ser um endereço de e-mail válido.",
                "ends_with": "O campo {attribute} deve terminar com um dos seguintes: {values}",
                "enum": "O valor selecionado para o campo {attribute} é inválido.",
                "exists": "O campo {attribute} selecionado é inválido.",
                "extensions": "O campo {attribute} deve ter uma das seguintes extensões {values}.",
                "file": "O campo {attribute} deve ser um arquivo.",
                "filled": "O campo {attribute} deve ter um valor.",
                "gt": {
                    "array": "O campo {attribute} deve conter mais de {value} itens.",
                    "file": "O campo {attribute} deve ser maior que {value} kilobytes.",
                    "numeric": "O campo {attribute} deve ser maior que {value}.",
                    "string": "O campo {attribute} deve ser maior que {value} caracteres."
                },
                "gte": {
                    "array": "O campo {attribute} deve conter {value} itens ou mais.",
                    "file": "O campo {attribute} deve ser maior ou igual a {value} kilobytes.",
                    "numeric": "O campo {attribute} deve ser maior ou igual a {value}.",
                    "string": "O campo {attribute} deve ser maior ou igual a {value} caracteres."
                },
                "image": "O campo {attribute} deve ser uma imagem de um dos seguintes tipos 'jpg', 'png', 'gif', 'bmp', 'svg' ou 'webp')",
                "in": "O campo {attribute} selecionado é inválido. Valores permitidos: {values}",
                "in_array": "O campo {attribute} não existe em {other}.",
                "integer": "O campo {attribute} deve ser um número inteiro.",
                "ip": "O campo {attribute} deve ser um endereço de IP válido.",
                "ipv4": "O campo {attribute} deve ser um endereço IPv4 válido.",
                "ipv6": "O campo {attribute} deve ser um endereço IPv6 válido.",
                "json": "O campo {attribute} deve ser uma string JSON válida.",
                "lt": {
                    "array": "O campo {attribute} deve conter menos de {value} itens.",
                    "file": "O campo {attribute} deve ser menor que {value} kb.",
                    "numeric": "O campo {attribute} deve ser menor que {value}.",
                    "string": "O campo {attribute} deve ser menor que {value} caracteres."
                },
                "lte": {
                    "array": "O campo {attribute} não deve conter mais que {value} itens.",
                    "file": "O campo {attribute} deve ser menor ou igual a {value} kb.",
                    "numeric": "O campo {attribute} deve ser menor ou igual a {value}.",
                    "string": "O campo {attribute} deve ser menor ou igual a {value} caracteres."
                },
                "mac_address": "O campo {attribute} precisa ser um endereço MAC válido.",
                "max": {
                    "array": "O campo {attribute} não pode ter mais do que {max} itens.",
                    "file": "O campo {attribute} não pode ser superior a {max} kb.",
                    "numeric": "O campo {attribute} não pode ser superior a {max}.",
                    "string": "O campo {attribute} não pode ter mais que {max} caracteres."
                },
                "max_digits": "O campo {attribute} não deve ter mais que {max} dígitos.",
                "mimes": "O campo {attribute} deve ser um arquivo do tipo: {values}.",
                "mimetypes": "O campo {attribute} deve ser um arquivo do tipo: {values}.",
                "min": {
                    "array": "O campo {attribute} deve ter pelo menos {min} itens.",
                    "file": "O campo {attribute} deve ter pelo menos {min} kb.",
                    "numeric": "O campo {attribute} deve ser maior ou igual a {min}.",
                    "string": "O campo {attribute} deve ter pelo menos {min} caracteres."
                },
                "min_digits": "O campo {attribute} deve ter no mínimo {min} dígitos.",
                "multiple_of": "O campo {attribute} deve ser um múltiplo de {value}.",
                "not_in": "O campo {attribute} selecionado é inválido.",
                "not_regex": "O campo {attribute} possui um formato inválido.",
                "numeric": "O campo {attribute} deve ser um número.",
                "password": {
                    "letters": "O campo {attribute} precisa conter letras",
                    "mixed": "O campo {attribute} precisa conter letras maiúsculas e minusculas",
                    "numbers": "O campo {attribute} precisa conter ao menos um número.",
                    "symbols": "O campo {attribute} precisa conter ao menos um símbolo",
                    "uncompromised": "Essa senha já apareceu em um vazamento de dados. Por favor, escolha outra."
                },
                "phone": "O campo {attribute} contém um telefone inválido.",
                "present": "O campo {attribute} deve estar presente.",
                "prohibited": "O campo {attribute} é proibido.",
                "prohibited_if": "O campo {attribute} é proibido quando {other} for {value}.",
                "prohibited_unless": "O campo {attribute} é proibido exceto quando {other} for {values}.",
                "regex": "O campo {attribute} tem um formato inválido.",
                "required": "O campo {attribute} é obrigatório.",
                "required_if": "O campo {attribute} é obrigatório quando {other} for {value}.",
                "required_unless": "O campo {attribute} é obrigatório exceto quando {other} for {values}.",
                "required_with": "O campo {attribute} é obrigatório quando {values} está presente.",
                "required_with_all": "O campo {attribute} é obrigatório quando {values} está presente.",
                "required_without": "O campo {attribute} é obrigatório quando {values} não está presente.",
                "required_without_all": "O campo {attribute} é obrigatório quando nenhum dos {values} estão presentes.",
                "same": "Os campos {attribute} e {other} devem corresponder.",
                "size": {
                    "array": "O campo {attribute} deve conter {size} itens.",
                    "file": "O campo {attribute} deve ser {size} kb.",
                    "numeric": "O campo {attribute} deve ser {size}.",
                    "string": "O campo {attribute} deve ter {size} caracteres."
                },
                "starts_with": "O campo {attribute} deve começar com um dos seguintes valores: {values}",
                "string": "O campo {attribute} deve ser um texto.",
                "timezone": "O campo {attribute} deve ser um fuso horário válido.",
                "ulid": "O campo {attribute} deve ser um ULID válido.",
                "unique": "O campo {attribute} já está sendo utilizado.",
                "uploaded": "Ocorreu uma falha no upload do campo {attribute}.",
                "url": "O campo {attribute} tem um formato inválido.",
                "uuid": "O campo {attribute} deve ser um UUID válido.",
                "attributes": {
                    "title": "título",
                    "name": "nome",
                    "email": "e-mail",
                    "password": "senha",
                    "password_confirmation": "confirmação da senha",
                    "about": "sobre",
                    "description": "descrição",
                    "phone": "telefone",
                    "country": "país",
                    "value": "valor",
                    "domain": "domínio",
                    "domain_name": "nome de domínio",
                    "subdomain": "subdomínio",
                    "type": "tipo",
                    "zipcode": "cep",
                    "street": "rua",
                    "number": "número",
                    "neighborhood": "bairro",
                    "city": "cidade",
                    "city_name": "cidade",
                    "state/uf": "estado/uf",
                    "complement": "complemento",
                    "document_type": "tipo de documento",
                    "document_number": "número do documento",
                    "file": "arquivo",
                    "image": "imagem",
                    "avatar": "avatar",
                    "available": "disponível",
                    "locale": "localização",
                    "language": "idioma",
                    "theme": "tema",
                    "dark_theme": "tema escuro",
                    "public": "público",
                    "color": "cor",
                    "date": "data",
                    "uf": "uf",
                    "state": "estado",
                    "status": "status",
                    "label": "rótulo",
                    "secret": "segredo",
                    "currency": "moeda",
                    "role": "função",
                    "current_password": "senha atual",
                    "categories": "categorias",
                    "category": "category",
                    "user_name": "nome de usuário",
                    "code": "código",
                    "date_description": "descrição das datas",
                    "location_description": "descrição do local",
                    "passport": "passaporte",
                    "start": "começo",
                    "start_at": "data de início",
                    "start_time": "hora de início",
                    "start_date": "data de início",
                    "frequency_interval": "intervalo da frequência",
                    "frequency_filters": "filtros",
                    "frequency_exceções": "exceções",
                    "end": "fim",
                    "all_day": "dia todo",
                    "repeat": "repetir",
                    "end_at": "data de encerramento",
                    "quantity": "quantidade",
                    "discount_percentage": "Desconto em porcentagem",
                    "discount_amount": "desconto",
                    "discount": "desconto",
                    "discount_type": "tipo de desconto",
                    "installment": "parcela",
                    "installments": "parcela",
                    "pix_key": "chave PIX",
                    "holder_full_name": "nome completo do titular",
                    "holder_document_type": "tipo de documento do titular",
                    "holder_document_number": "número de documento do titular",
                    "bank_account_bank": "banco",
                    "bank_account_type": "tipo de conta bancária",
                    "bank_account_number": "número da conta bancária",
                    "bank_account_number_digit": "dígito verificador da conta bancária",
                    "bank_account_agency": "agência da conta bancária",
                    "bank_account_agency_digit": "dígito verificador da agência",
                    "dates": "datas",
                    "dates_description": "descrição de datas",
                    "location": "local",
                    "ticket_batch": "lote de ingresso",
                    "ticket_type": "ingresso",
                    "confirmation": "confirmação",
                    "client_id": "client id",
                    "client_secret": "client secret",
                    "webhook_secret": "webhook secret",
                    "webhook_signature": "assinatura do webhook",
                    "api_url": "api url",
                    "disclaimer": "exoneração de responsabilidade",
                    "terms": "termos",
                    "google_analytics_code": "código do google analytics",
                    "host": "anfitrião/anfitriã",
                    "hosts": "anfitriões",
                    "search": "busca",
                    "venue": "local",
                    "tags": "tags",
                    "publish_date": "data de publicação",
                    "end_date": "data de encerramento",
                    "end_time": "hora de encerramento",
                    "interval": "intervalo",
                    "repeat_until_count": "repetir até",
                    "repeat_until_date": "repetir até a data",
                    "repeat_until_time": "repetir até a hora",
                    "help_text": "texto de ajuda",
                    "select_options": "opções do seletor",
                    "roles": "permissões"
                }
            },
            "edit": "editar",
            "create": "novo",
            "All rights reserved.": "Todos os direitos reservados",
            "services": []
        }
    }
]